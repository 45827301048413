:root {
    --primary-color-dark-blue-1: hsl(217, 28%, 15%);
    --primary-color-dark-blue-2: hsl(218, 28%, 13%);
    --primary-color-dark-blue-3: hsl(216, 53%, 9%);
    --primary-color-dark-blue-4: hsl(219, 30%, 18%);
    --accent-color-cyan: #65e2d9;
    --accent-color-blue: hsl(198, 60%, 50%);
    --neutral-color-white: hsl(0, 0%, 100%);
    --padding-sm: 10px 2rem;
    --padding-md: 20px 3rem;
    --padding-lg: 30px 4rem;
    --padding-footer: 15rem 4rem 1rem;
    --padding-footer-tablet: 20rem 3rem 1rem;
    --padding-footer-mobile: 30rem 3rem 1rem;
}

.landing {
    position: fixed;
    top: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 0%;
    max-height: 100%;
    background-color: var(--primary-color-dark-blue-3);
    color: var(--neutral-color-white);
    font-family: var(--font-body);
    transition: 600ms;
    header {
        display: none;
        padding: var(--padding-md);
        justify-content: space-between;
    }

    &.open {
        height: 100%;
        overflow-y: auto;

        header {
            display: flex;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--font-heading);
        line-height: 1.7;
        font-weight: 700;
    }

    h1 {
        font-size: 1.7rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.4rem;
    }

    p {
        font-family: var(--font-body);
        font-size: 14px;
        line-height: 1.7;
        padding: 0.5rem 0;
    }

    .lg-p {
        font-size: 1rem;
    }

    .landing a {
        color: var(--neutral-color-white);
        font-family: var(--font-heading);
        text-decoration: none;
    }

    .nav-bar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-bar ul li {
        display: inline-block;
        margin-left: 1rem;
    }

    a:hover {
        color: var(--accent-color-cyan);
        transition: 600ms;
    }

    section {
        padding: var(--padding-lg);
    }

    .intro img,
    .productive img {
        width: 100%;
        max-width: 600px;
        height: auto;
    }

    .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .intro > div {
        width: 60%;
    }

    .ml-1 {
        margin-left: 1rem;
    }

    .mt-2 {
        margin-top: 2rem;
    }

    button {
        border-radius: 25px;
        background: linear-gradient(50deg, var(--accent-color-cyan), var(--accent-color-blue));
        border: none;
        font-family: var(--font-heading);
        color: var(--neutral-color-white);
        height: 3rem;
        font-weight: 700;
        padding: 0 12px;
    }

    input {
        height: 3rem;
        padding-left: 12px;
        border: none;
        border-radius: 25px;
        background-color: var(--neutral-color-white);
    }

    .btn {
        width: 15rem;
        max-width: 80%;
    }

    button:hover {
        cursor: pointer;
        background: linear-gradient(50deg, var(--accent-color-blue), var(--accent-color-cyan));
    }

    .features {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .features-item {
        width: 33.33%;
        padding: 2rem 2.5rem;
        text-align: center;
    }

    .productive {
        display: flex;
        align-items: center;
    }

    .productive-content {
        padding-left: 4.5rem;
    }

    .content-link {
        font-family: var(--font-body);
        border-bottom: 1px solid var(--accent-color-cyan);
        color: var(--accent-color-cyan);
    }

    .content-link:hover {
        color: var(--neutral-color-white);
        transition: 600ms;
        border-bottom-color: var(--white);
    }

    .testimonial {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .testimonial-item {
        margin-left: 3.5rem;
        flex-basis: 33.33%;
        border-radius: 5px;
        background-color: var(--primary-color-dark-blue-4);
        box-shadow: 2px 2px 20px 2px var(--primary-color-dark-blue-3);
        padding: var(--padding-sm);
    }

    .testimonial-item:first-child {
        margin-left: 0;
    }

    .testimonial-item-reviewer {
        display: flex;
        padding-top: 1rem;
    }

    .testimonial-item-reviewer img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
    }

    .cta {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cta-item {
        position: absolute;
        top: 50px;
        max-width: 800px;
        width: 66.66%;
        min-width: 300px;
        padding: var(--padding-md);
        box-shadow: 2px 2px 30px 2px var(--primary-color-dark-blue-3);
        background-color: var(--primary-color-dark-blue-4);
        border-radius: 5px;
        text-align: center;
    }

    .cta-form {
        display: flex;
        justify-content: center;
    }

    .input-block {
        width: 80%;
        max-width: 600px;
    }

    .btn-block {
        width: 20%;
        max-width: 250px;
        margin-left: 1rem;
    }

    .contact-info {
        margin-top: 50px;
        padding: var(--padding-footer);
        display: grid;
        background-color: var(--primary-color-dark-blue-2);
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas: "logo logo logo logo" "location contacts links social" "attribution attribution attribution attribution";
    }

    .contact-info > div,
    .contact-info > nav {
        padding: 1rem 2.5rem;
    }

    .contact-info-logo {
        grid-area: logo;
    }

    .contact-info-location {
        grid-area: location;
    }

    .contact-info-contacts-item,
    .contact-info-location-item {
        display: flex;
        align-items: center;
    }

    footer p {
        padding: 0;
    }

    .img-footer {
        margin-right: 1rem;
        width: 18px;
        height: 18px;
        line-height: 1.5;
    }

    .contact-info-contacts {
        grid-area: contacts;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-info-contacts-item:first-child {
        margin-bottom: 2rem;
    }

    .contact-info-links {
        grid-area: links;
        display: flex;
        justify-content: center;
    }

    .contact-info-links-list:first-child {
        margin-right: 2.5rem;
    }

    .contact-info-links-list li {
        margin-bottom: 1.5rem;
    }

    .contact-info-social-links {
        display: flex;
        justify-content: flex-end;
        grid-area: social;
    }

    .contact-info-social-link-item {
        margin-left: 1rem;
        height: 30px;
        width: 30px;
        border: 1px solid var(--neutral-color-white);
        border-radius: 50%;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer i {
    }

    .attribution-container {
        grid-area: attribution;
    }

    .attribution {
        font-size: 11px;
        text-align: center;
    }

    .attribution a {
        color: hsl(228, 45%, 44%);
    }

    @media (max-width: 1000px) {
        header img,
        footer img {
            width: 117px;
            height: auto;
        }

        section {
            padding: var(--padding-sm);
        }

        .intro > div,
        .features-item {
            width: 100%;
        }

        .testimonial,
        .productive {
            flex-direction: column;
        }

        .features-item {
            padding: 2rem 0;
        }

        .productive-content {
            margin-top: 2rem;
            padding: 0;
        }

        .testimonial-item {
            margin-left: 0;
            margin-top: 3.5rem;
            width: 80%;
        }

        .cta-item {
            width: 80%;
            padding: 2.5rem 2rem;
        }

        .cta-form {
            flex-direction: column;
        }

        .input-block {
            max-width: 100%;
            width: auto;
        }

        .btn-block {
            max-width: 100%;
            width: auto;
            min-width: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }

        .contact-info {
            padding: var(--padding-footer-tablet);
            grid-template-columns: 100%;
            grid-template-rows: repeat(6, auto);
            grid-template-areas: "logo" "location" "contacts" "links" "social" " attribution";
        }

        .contact-info > div,
        .contact-info > nav {
            padding: 1rem 0;
        }

        .contact-info-contacts {
            align-items: flex-start;
        }

        .contact-info-links {
            flex-direction: column;
            justify-content: flex-start;
        }

        .contact-info-links-list:first-child {
            margin-bottom: 1rem;
        }

        .contact-info-social-links {
            justify-content: center;
        }
    }

    @media (max-width: 375px) {
        section {
            padding: var(--padding-sm);
        }

        .cta-item {
            min-width: 50%;
            max-width: 70%;
        }

        .contact-info {
            padding: var(--padding-footer-mobile);
        }
    }

    .iconHue {
        filter: hue-rotate(326deg);
    }

    .intro-img {
        max-height: 50%;
    }
}

.landing::-webkit-scrollbar {
    width: 0.5rem;
}

.landing::-webkit-scrollbar-track {
    background: var(--primary-color-dark-blue-3);
}

.landing::-webkit-scrollbar-thumb {
    background: var(--primary-color-dark-blue-1);
}
