$total: 200;
$size: 50;
$time: 20;

@function posOrNeg() {
    @return random() * 2 - 1;
}

.whirlwind {
    background: radial-gradient(white, #ffffff 10%, #ffffff 30%, #dcdcdc);
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
    filter: blur(1px);

    .wrap {
        position: relative;
        height: 100%;
        transform-style: preserve-3d;
        perspective: 1000px;
        cursor: pointer;
    }
.c {
    position: absolute;
    width: $size+px;
    height: $size+px;
    background: #ddd;
    top: 50%;
    left: 50%;
    margin-left: -$size/2+px;
    margin-top: -$size/2+px;
    animation: center $time+s infinite linear;
    animation-direction: reverse;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .2);
    opacity: 0;
}

@for $i from 1 through $total {
    .c:nth-child(#{$i}) {
        $hue: 180;
        transform: rotateY(0) translate3d((random(1000) + 200) * posOrNeg()+px, (random(1000) + 200) * posOrNeg()+px, random(500) * posOrNeg()+px) scale(0);
        //background: hsla($hue, 100%, 50%, .7);
        animation-delay: -($i * ($time/$total))+s;
        transition: .8s;
    }
}

@keyframes center {
    50% {
        opacity: 1;
    }

    90% {
        opacity: 0;
    }

    100% {
        transform: rotateY(360deg) translate3d(0, 0, 0) scale(1);
    }
}
}

