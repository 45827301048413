@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@500&family=Roboto:wght@900&family=Voces&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* antialiasing */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fefffd;
    color: #1a1b1e;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

h1 {
    &.title {
        font-family: "Roboto", sans-serif;
        font-size: clamp(30px, 10vw, 60px);
        font-weight: 900;
        color: #000;
    }
}

p {
    line-height: 1.1;
    .small {
        font-size: 70%;
      }
    &.small {
        font-size: 70%;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

/* button {
  font-family: 'Prompt', sans-serif;
  font-size: clamp(16px, 5vh, 24px);
  font-weight: 500;
  color: #000;
  background: #fff;
  border: 1px solid #000;
  padding: 5px 20px;
  min-width: clamp(100px, 30vw, 200px);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #000;
    color: #fff;
  }
} */

.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ph-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.scale50 {
    transform: scale(0.5);
}

.scale75 {
    transform: scale(0.75);
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-a {
    margin-top: auto;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}


.flex-aic {
    display: flex;
    align-items: center;
}

.flex {
    display: flex;
  &.aic {
    align-items: center;
  }

  &.jcc {
    justify-content: center;
  }

  &.jcsb {
    justify-content: space-between;
  }

  &.col {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.ml-a {
    margin-left: auto;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.c-point {
    cursor: pointer;
}

.green {
    color: #00897b;
}

.gray {
    color: #757575;
}

.op50 {
    opacity: 0.5;
}

.font75 {
    font-size: 75%;
}

/* mobile media query */
@media (max-width: 768px) {
    .mobileCol {
        flex-direction: column;

        &.reverse {
            flex-direction: column-reverse;
        }
    }
}