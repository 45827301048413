.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .container {
  width: 100%;
  height: 100%;
  position: relative;
}

.dashboard {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .sidebar {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    &.active {
      left: -100%;
    }

    .sidebar-header {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #000;

      h3 {
        font-family: 'Roboto', sans-serif;
        font-size: clamp(30px, 10vh, 60px);
        font-weight: 900;
        color: #000;
      }
    }

    .sidebar-menu {
      width: 100%;
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #000;

          a {
            font-family: 'Prompt', sans-serif;
            font-size: clamp(16px, 5vh, 24px);
            font-weight: 500;
            color: #000;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: #fff;
              background: #000;
            }
          }
        }
      }
    }
  }
} */

.loginAction {
  font-family: 'Prompt', sans-serif;
    font-size: clamp(12px, 4vh, 20px);
    font-weight: 500;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px 20px;
    min-width: clamp(100px, 30vw, 200px);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  
    &:hover {
      background: #000;
      color: #fff;
    }
}

.collapse-container {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  z-index: 100;
  position: absolute;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid #e6eaea;
  border-top: 2px solid #e6eaea;

  &.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }

  &.active {
    max-height: 500px;
    padding: 20px;
    overflow-y: scroll;
  }

  &.white {
    background: #f5f5f5;
  }

    &::-webkit-scrollbar {
      width: 4px;
      background: rgba(0, 0, 0, 0);
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
}

.tutorial {
  background: #e4e3e3;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
}

.openingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contextButton {
  --active: 0;
}

#contextButton:is(:hover, :focus-visible) {
  --active: 1;
}

#setcontextButtontings:is(:hover, :focus-visible) path {
  animation-name: bounce;
  color: #ffdd00;
}

svg {
  overflow: visible !important;
}


.sparkle path {
  color: #fff;
  transform-box: fill-box;
  transform-origin: center;
  fill: currentColor;
  stroke: currentColor;
  animation-name: infinite-color-cycle;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* color animation */

.sparkle path:nth-of-type(1) {
  --scale: 0.5;
  --delay: 0.1;
  --base: 40%;
}

.sparkle path:nth-of-type(2) {
  --scale: 1.5;
  --delay: 0.2;
  --base: 20%;
}

.sparkle path:nth-of-type(3) {
  --scale: 2.5;
  --delay: 0.35;
  --base: 30%;
}

.workshopItem {
  margin: 10px 5px;
  padding: 5px 10px;
  padding-top: 10px;
  width: clamp(250px, 100%, 30%);
  background: rgb(238, 240, 240);
  border-radius: 5px;
  border: 2px solid #e6eaea;
  display: flex;
  flex-direction: column;

  label {
    font-size: 80%;
    opacity: .8;
  }

  .icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background: #d8d8d8;
    }
  }
}

.workArea {
    overflow-y: auto;
    color: white;
    margin: 10px;
    margin-right: 5px;
}

.tab {
    float: left;
    border: none;
    padding: 10px 0;
    background: #32465a;
    flex-grow: 1;
    color: #f5f5f5;
    cursor: pointer;
    font-size: 0.85em;
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    border-bottom: 2px solid transparent;

    &.selected {
      border-bottom: 2px solid #2ecc70;
    }

    &:hover {
      background: #435f7a;;
    }
}

.workSpace {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0%;
  overflow: hidden;

  &.open {
    height: 100%;
  }

  .briefContainer {
    background: #ffffff0a;
    padding: 5px 10px;
    border-radius: 5px;
    height: 100%;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.actionFillButton {
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 7px 18px 7px;
  width: auto;
  height: fit-content;
  text-align: center;
  background-color: transparent;
  border: none;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: .75;
  }
}

.actionFillButton span {
  position: relative;
  color: #fff;
}

.actionFillButton .wave {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(45deg, #4f00bc, #29abe2);
  transition: .5s ease;

  &.green {
    background: linear-gradient(45deg, #2ecc70, #29abe2);
  }

  &.dark {
    background: linear-gradient(45deg, #2c3e50, #151d26);
  }

  &.red {
    background: linear-gradient(45deg, #dc7e02, #d8200c);
  }
}

.actionFillButton.loading .wave::after,
.actionFillButton.loading .wave::before {
  width: 200%;
  height: 200%;
  transition: 2s ease;
}

.actionFillButton .wave::after,
.actionFillButton .wave::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -75%);
  transition: .5s ease;
}

.actionFillButton .wave::before {
  border-radius: 40%;
  background: rgba(1, 1, 1, .5);
  animation: wave 7s linear infinite;
}

.actionFillButton .wave::after {
  border-radius: 45%;
  background: #333;
  animation: wave 12s linear infinite;
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

.text-gradient {
  color: #2ecc70;
  background-image: linear-gradient(45deg, #2ecc70, #29abe2);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.titleLabel {
  font-size: .7em;
}

.textWrapper {
  width: 0px;
  display: flex;
  flex-direction: column;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.textBar {
  height: 11px;
  background: #eee;
  margin-bottom: 5px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #dfdfdf 4%, #d2cdcd 25%, #d9dbdc 36%);
  background-size: 1000px 100%;
}

.textBar:nth-child(4),
.textBar:nth-child(12) {
  width: 66%;
  margin-bottom: 15px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.tags__control {
  border: none !important;
  box-shadow: none !important;
  min-height: 30px;
}

.tags__menu {
  margin: 0 !important;

  ::-webkit-scrollbar {
    width: 3px;
    background: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.configurationInput {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    font-size: 80%;
    opacity: .8;
  }

  input {
    background: transparent;
    border: none;
    box-shadow: none !important;
    border-bottom: 1px solid #d7d8d8;
    color: #2c3e50;
    padding: 3 px 0;
    outline: none !important;
  }
}

.noteArea {
  background: rgba(255, 255, 255, 0.04);
  padding: 10px;
  border: 1px solid #ffffff1b;
  color: white;
  border-radius: 5px;
  height: 100%;
  overflow-x: hidden;
  outline: none !important;
  font-family: ProximaNova, sans-serif;
  resize: none;
}

.mAction {
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  color: #bcbbbb;

  &.green {
    color: #2ecc70;
  }

  &:hover {
    background: #0000001a;
  }
}

.notesContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding: 0;
  padding-top: 10px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 3px;
    background: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.noteContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff0f;
    padding: 10px 15px;
    border: 3px solid #6060600f;
    margin-bottom: 10px;
}

.bookmark {
  background: #ffffff0f;
  padding: 10px 15px;
  border: 3px solid #6060600f;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.conversationLink.active .circle{
  border: 2px solid #27ae60 !important;
}

.conversationLink.active .contact {
  background: #32465a;
}

.glassFrame {
  background: linear-gradient(45deg, transparent, #f1f1f196, transparent);
    padding: 80px;
    border: 5px solid #cccccc1f;
    border-style: groove;
    border-radius: 10px;
    backdrop-filter: blur(2px);
}

.filterTag {
      padding: 5px 10px;
        background: #32465a;
        border-radius: 25px;
        color: white;
        line-height: 1;
        font-size: 70%;
        min-width: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        cursor: pointer;

        i {
          margin-left: 5px;
        }
}