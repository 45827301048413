$white: #f5f5f5;
$dark: #2c3e50;
$lighter: #32465a;
$lightest: #496886;
$light: #435f7a;
$red: #e74c3c;
$green: #2ecc71;
$yellow: #f1c40f;
$gray: #95a5a6;
$break: 735px;
$break-big: 900px;

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #27ae5f00;
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    letter-spacing: 0.1px;
    color: $lighter;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 2px solid #27ae60;
    float: left;
    margin-right: 25px;

    @media screen and (max-width: $break) {
        margin-right: 10px;
    }
}

#frame {
    min-width: 360px;
    width: 100vw;
    /* max-width: 1000px; */
    height: 100vh;
    min-height: 300px;
    background: #e6eaea;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    #sidepanel {
        float: left;
        min-width: 280px;
        max-width: 340px;
        width: 40%;
        height: 100%;
        background: $dark;
        color: $white;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;

        #conversationButton i {
            margin-right: 10px;
            @media screen and (max-width: $break) {
                margin-right: 0;
            }
        }

        #contextButton {
            width: 80%;

            svg {
                inline-size: 1.4em;
                translate: 50% 10%;

                @media screen and (max-width: $break) {
                    translate: 0;
                }
            }
        }

        @media screen and (max-width: $break) {
            width: 58px;
            min-width: 58px;

            #search.conversation {
                display: none;
            }

            #conversationButton {
                display: none;
            }

            &.open {
                width: 40%;
                min-width: 300px;
                overflow: visible;
                transition: min-width 0.3s ease-in-out;

                #contextButton {
                    width: 80%;
                    @media screen and (max-width: $break) {
                        span {
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }

                #bottom-bar button {
                    width: 50%;
                }

                #conversationButton {
                    width: 20%;
                    display: block;
                    float: left;
                    border-right: 1px solid $lighter;
                }
            }
        }

        #profile {
            /* width: 80%;
            margin: 25px auto; */
            padding: 20px;
            padding-top: 0;

            /* @media screen and (max-width: $break) {
                width: 100%;
                margin: 0 auto;
                padding: 5px 0 0 0;
                background: $lighter;
            } */

            &.expanded {
                .wrap {
                    height: 210px;
                    line-height: initial;

                    p {
                        margin-top: 20px;
                    }

                    i.expand-button {
                        -moz-transform: scaleY(-1);
                        -o-transform: scaleY(-1);
                        -webkit-transform: scaleY(-1);
                        transform: scaleY(-1);
                        filter: FlipH;
                        -ms-filter: "FlipH";
                    }
                }
            }

            .wrap {
                height: 60px;
                line-height: 60px;
                overflow: hidden;
                @include transition(0.3s height ease);

                @media screen and (max-width: $break) {
                    height: 55px;
                }

                img {
                    width: 50px;
                    border-radius: 50%;
                    padding: 3px;
                    border: 2px solid $red;
                    height: auto;
                    float: left;
                    cursor: pointer;
                    @include transition(0.3s border ease);

                    @media screen and (max-width: $break) {
                        width: 40px;
                        margin-left: 4px;
                    }

                    &.online {
                        border: 2px solid $green;
                    }

                    &.away {
                        border: 2px solid $yellow;
                    }

                    &.busy {
                        border: 2px solid $red;
                    }

                    &.offline {
                        border: 2px solid $gray;
                    }
                }

                .circle {
                    width: 50px;
                    border-radius: 50%;
                    padding: 3px;
                    border: 2px solid $red;
                    height: auto;
                    float: left;
                    cursor: pointer;
                    @include transition(0.3s border ease);

                    @media screen and (max-width: $break) {
                        width: 40px;
                        margin-left: 4px;
                    }

                    &.online {
                        border: 2px solid $green;
                    }

                    &.away {
                        border: 2px solid $yellow;
                    }

                    &.busy {
                        border: 2px solid $red;
                    }

                    &.offline {
                        border: 2px solid $gray;
                    }
                }

                p {
                    float: left;
                    margin-left: 15px;

                    /* @media screen and (max-width: $break) {
                        display: none;
                    } */
                }

                i.expand-button {
                    float: right;
                    margin-top: 23px;
                    font-size: 0.8em;
                    cursor: pointer;
                    color: $light;

                    @media screen and (max-width: $break) {
                        display: none;
                    }
                }

                #status-options {
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    width: 150px;
                    margin: 70px 0 0 0;
                    border-radius: 6px;
                    z-index: 99;
                    line-height: initial;
                    background: $light;
                    @include transition(0.3s all ease);

                    @media screen and (max-width: $break) {
                        width: 58px;
                        margin-top: 57px;
                    }

                    &.active {
                        opacity: 1;
                        visibility: visible;
                        margin: 75px 0 0 0;

                        @media screen and (max-width: $break) {
                            margin-top: 62px;
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 8px solid $light;
                        margin: -8px 0 0 24px;

                        @media screen and (max-width: $break) {
                            margin-left: 23px;
                        }
                    }

                    ul {
                        overflow: hidden;
                        border-radius: 6px;

                        li {
                            padding: 15px 0 30px 18px;
                            display: block;
                            cursor: pointer;

                            @media screen and (max-width: $break) {
                                padding: 15px 0 35px 22px;
                            }

                            &:hover {
                                background: $lightest;
                            }

                            span.status-circle {
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                margin: 5px 0 0 0;

                                @media screen and (max-width: $break) {
                                    width: 14px;
                                    height: 14px;
                                }

                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 14px;
                                    height: 14px;
                                    margin: -3px 0 0 -3px;
                                    background: transparent;
                                    border-radius: 50%;
                                    z-index: 0;

                                    @media screen and (max-width: $break) {
                                        height: 18px;
                                        width: 18px;
                                    }
                                }
                            }

                            p {
                                padding-left: 12px;

                                @media screen and (max-width: $break) {
                                    display: none;
                                }
                            }

                            &#status-online {
                                span.status-circle {
                                    background: $green;
                                }

                                &.active {
                                    span.status-circle {
                                        &:before {
                                            border: 1px solid $green;
                                        }
                                    }
                                }
                            }

                            &#status-away {
                                span.status-circle {
                                    background: $yellow;
                                }

                                &.active {
                                    span.status-circle {
                                        &:before {
                                            border: 1px solid $yellow;
                                        }
                                    }
                                }
                            }

                            &#status-busy {
                                span.status-circle {
                                    background: $red;
                                }

                                &.active {
                                    span.status-circle {
                                        &:before {
                                            border: 1px solid $red;
                                        }
                                    }
                                }
                            }

                            &#status-offline {
                                span.status-circle {
                                    background: $gray;
                                }

                                &.active {
                                    span.status-circle {
                                        &:before {
                                            border: 1px solid $gray;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                #expanded {
                    padding: 100px 0 0 0;
                    display: block;
                    line-height: initial !important;

                    label {
                        float: left;
                        clear: both;
                        margin: 0 8px 5px 0;
                        padding: 5px 0;
                    }

                    input {
                        border: none;
                        margin-bottom: 6px;
                        background: $lighter;
                        border-radius: 3px;
                        color: $white;
                        padding: 7px;
                        width: calc(100% - 43px);

                        &:focus {
                            outline: none;
                            background: $light;
                        }
                    }
                    textarea {
                        border: none;
                        margin-bottom: 6px;
                        background: $lighter;
                        border-radius: 3px;
                        color: $white;
                        padding: 7px;
                        resize: none;
                        width: calc(100% - 43px);

                        &:focus {
                            outline: none;
                            background: $light;
                        }
                    }
                }
            }
        }

        #search {
            border-top: 1px solid $lighter;
            border-bottom: 1px solid $lighter;
            font-weight: 300;

            label {
                position: absolute;
                margin: 10px 0 0 20px;
            }

            input {
                font-family: "proxima-nova", "Source Sans Pro", sans-serif;
                padding: 10px 0 10px 46px;
                width: 100%;
                height: 40px;
                border: none;
                background: $lighter;
                color: $white;

                &:focus {
                    outline: none;
                    background: $light;
                }

                &::-webkit-input-placeholder {
                    color: $white;
                }

                &::-moz-placeholder {
                    color: $white;
                }

                &:-ms-input-placeholder {
                    color: $white;
                }

                &:-moz-placeholder {
                    color: $white;
                }
            }

            textarea {
                font-family: "proxima-nova", "Source Sans Pro", sans-serif;
                padding: 10px 0 10px 46px;
                width: 100%;
                border: none;
                background: $lighter;
                color: $white;
                resize: none;

                &:focus {
                    outline: none;
                    background: $light;
                }

                &::-webkit-input-placeholder {
                    color: $white;
                }

                &::-moz-placeholder {
                    color: $white;
                }

                &:-ms-input-placeholder {
                    color: $white;
                }

                &:-moz-placeholder {
                    color: $white;
                }
            }
        }

        #contacts {
            height: calc(100% - 140px);
            overflow-y: scroll;
            overflow-x: hidden;
            transition: height 0.5s ease-in-out;

            &.accountOpen {
                height: calc(100% - 210px);
            }

            @media screen and (max-width: $break) {
                height: calc(100% - 149px);
                overflow-y: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &.expanded {
                height: calc(100% - 334px);
            }

            &::-webkit-scrollbar {
                width: 8px;
                background: $dark;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #243140;
            }

            ul {
                li.contact {
                    position: relative;
                    padding: 10px 0;
                    font-size: 0.9em;
                    cursor: pointer;

                    @media screen and (max-width: $break) {
                        padding: 7px 8px;
                    }

                    &:hover {
                        background: $lighter;
                    }

                    &.active {
                        background: $lighter;
                        border-right: 5px solid $light;

                        span.contact-status {
                            border: 2px solid $lighter !important;
                        }
                    }

                    .wrap {
                        width: 88%;
                        margin: 0 auto;
                        position: relative;
                        display: flex;
                        align-items: center;

                        @media screen and (max-width: $break) {
                            width: 100%;
                        }

                        span {
                            position: absolute;
                            left: 0;
                            margin: -2px 0 0 -2px;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            border: 2px solid $dark;
                            background: $gray;

                            &.online {
                                background: $green;
                            }

                            &.away {
                                background: $yellow;
                            }

                            &.busy {
                                background: $red;
                            }
                        }

                        img {
                            width: 40px;
                            border-radius: 50%;
                            float: left;
                            margin-right: 10px;

                            @media screen and (max-width: $break) {
                                margin-right: 0px;
                            }
                        }

                        .circle {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            font-size: 8px;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 2px solid #b8b8b894;
                            float: left;
                            margin-right: 10px;
                            &.active {
                                border: 2px solid #27ae60;
                            }
                            @media screen and (max-width: $break) {
                                margin-right: 0px;
                            }
                        }

                        .meta {
                            padding: 5px 0 0 0;

                            @media screen and (max-width: $break) {
                                display: none;
                            }

                            .name {
                                font-weight: 600;
                            }

                            .preview {
                                margin: 0;
                                padding: 0 0 1px;
                                font-weight: 400;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                @include transition(1s all ease);

                                span {
                                    position: initial;
                                    border-radius: initial;
                                    background: none;
                                    border: none;
                                    padding: 0 2px 0 0;
                                    margin: 0 0 0 1px;
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        #bottom-bar {
            position: absolute;
            width: 100%;
            bottom: 0;
            background: #2c3e50;
            @media screen and (max-width: $break) {
                flex-direction: row-reverse;
            }

            button {
                float: left;
                border: none;
                width: 50%;
                padding: 10px 0;
                background: $lighter;
                color: $white;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                font-size: 0.85em;
                font-family: "proxima-nova", "Source Sans Pro", sans-serif;

                @media screen and (max-width: $break) {
                    width: 100%;
                }

                &.selected {
                    border-bottom: 2px solid #2ecc70;
                    background: transparent;
                }

                &:focus {
                    outline: none;
                }

                &:nth-child(1) {
                    border-right: 1px solid $dark;

                    @media screen and (max-width: $break) {
                        border-right: none;
                        border-bottom: 1px solid $dark;
                    }
                }

                &:hover {
                    background: $light;
                }

                i {
                    margin-right: 3px;
                    font-size: 1em;

                    @media screen and (max-width: $break) {
                        font-size: 1.3em;
                    }
                }

                span {
                    @media screen and (max-width: $break) {
                        display: none;
                    }
                }
            }
        }

        #top-bar {
            width: 100%;
            top: 0;

            #sidepanel #conversationButton {
                display: none;
            }

            button {
                float: left;
                border: none;
                width: 50%;
                padding: 10px 0;
                background: transparent;
                color: $white;
                cursor: pointer;
                font-size: 0.85em;
                font-family: "proxima-nova", "Source Sans Pro", sans-serif;
                border-top: 2px solid transparent;

                @media screen and (max-width: $break) {
                    float: none;
                    width: 100%;
                    padding: 15px 0;
                }

                &.selected {
                    border-top: 2px solid #2ecc70;
                    background: $lighter;
                }

                &:focus {
                    outline: none;
                }

                &:nth-child(1) {
                    border-right: 1px solid $dark;

                    @media screen and (max-width: $break) {
                        border-right: none;
                        border-bottom: 1px solid $dark;
                    }
                }

                &:hover {
                    background: $light;
                }

                span {
                    @media screen and (max-width: $break) {
                        display: none;
                    }
                }
            }
        }
    }

    .content {
        float: right;
        width: 60%;
        height: 100%;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $break) {
            width: calc(100% - 58px);
            min-width: 300px !important;
        }

        @media screen and (min-width: $break-big) {
            width: calc(100% - 340px);
        }

        .contact-profile {
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: $white;

            img {
                width: 40px;
                border-radius: 50%;
                float: left;
                margin: 9px 12px 0 9px;
            }

            p {
                float: left;
            }

            .social-media {
                float: right;
                font-size: 20px;
                white-space: nowrap;

                i {
                    margin-left: 20px;
                    cursor: pointer;

                    &:nth-last-child(1) {
                        margin-right: 20px;
                    }

                    &:hover {
                        color: $light;
                    }

                    @media screen and (max-width: $break) {
                        margin-left: 10px;
                    }
                }
            }
        }

        .messages {
            height: auto;
            min-height: calc(100% - 150px);
            max-height: calc(100% - 150px);
            overflow-y: scroll;
            overflow-x: hidden;

            @media screen and (max-width: $break) {
                max-height: calc(100% - 150px);
            }

            &::-webkit-scrollbar {
                width: 8px;
                background: rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.3);
            }

            ul {
                li {
                    display: inline-block;
                    clear: both;
                    float: left;
                    padding: 25px;
                    margin: 0;
                    width: 100%;
                    font-size: 0.9em;
                    display: flex;

                    &:nth-last-child(1) {
                        margin-bottom: 20px;
                    }

                    &.sent {
                        img {
                            margin: 0;
                            margin-right: 25px;
                        }

                        /* p {
                            background: $light;
                            color: $white;
                        } */
                    }

                    &.replies {
                        /* background: $white; */
                        background: rgb(238 240 240);
                        img {
                            float: right;
                            margin: 0;
                        }

                        p {
                            float: left;
                        }
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        float: left;
                    }

                    p {
                        display: inline-block;
                        padding: 0;
                        border-radius: 20px;
                        /* max-width: 205px; */
                        line-height: 130%;

                        /* @media screen and (min-width: $break) {
                            max-width: 300px;
                        } */
                    }
                }
            }
        }

        .message-input {
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 99;

            .wrap {
                position: relative;

                input {
                    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
                    float: left;
                    border: none;
                    width: calc(100% - 50px);
                    padding: 11px 32px 10px 8px;
                    font-size: 0.8em;
                    color: $lighter;

                    @media screen and (max-width: $break) {
                        padding: 15px 32px 16px 8px;
                    }

                    &:focus {
                        outline: none;
                    }
                }
                textarea {
                    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
                    float: left;
                    border: none;
                    width: calc(100% - 50px);
                    height: 80px;
                    padding: 10px 37px 10px 15px;
                    font-size: 1em;
                    line-height: 20px;
                    color: $lighter;
                    background: #f5f5f5;
                    resize: none;

                    /* @media screen and (max-width: $break) {
                        padding: 15px 32px 16px 8px;
                    } */

                    &:focus {
                        outline: none;
                    }

                    &::-webkit-scrollbar {
                        width: 1px;
                        background: rgba(0, 0, 0, 0);
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 1px;
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                }
                .attachment {
                    position: absolute;
                    right: 70px;
                    z-index: 4;
                    margin-top: 10px;
                    font-size: 1.1em;
                    color: $light;
                    opacity: 0.5;
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                    }
                }

                button {
                    float: right;
                    border: none;
                    width: 50px;
                    padding: 12px 0;
                    cursor: pointer;
                    background: $lighter;
                    color: $white;

                    &:hover {
                        background: $light;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

.input-container {
    max-width: clamp(260px, 95%, 730px);
    max-height: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px #d7d7d7;
}

.message-container {
    display: flex;
    width: 100%;
    max-width: clamp(300px, 95%, 730px);
    margin: 0 auto;
    overflow: hidden;
}

.richContainer {
    display: flex;
    flex-direction: column;
    max-width: 87%;

    li {
        display: list-item !important;
        padding: 10px 0 !important;
        padding-left: 0 !important;
        margin: 0 !important;
        margin-left: 15px !important;
        padding-right: 15px !important;
    }

    table {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 15px 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }

    table caption {
        font-size: 1.5em;
        margin: 0.5em 0 0.75em;
    }

    table tr {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: 0.35em;
    }

    table th,
    table td {
        padding: 0.625em;
        text-align: center;
    }

    table th {
        font-size: 0.85em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
}
